@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .placeholder-italic::placeholder {
    font-style: italic;
    font-weight: 400;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
a:visited {
  color: inherit;
}

/* 
@font-face {
  font-family: 'FRALIGHT';
  src:  local("FRALIGHT"),
  url("./asset/font/FRALIGHT.TTF") format("truetype");
}
@font-face {
  font-family: 'FRAMEDIUM';
  src:  local("FRAMEDIUM"),
  url("./asset/font/FRAMEDIUM.TTF") format("truetype");
}
@font-face {
  font-family: 'FRAHEAVY';
  src:  local("FRAHEAVY"),
  url("./asset/font/FRAHEAVY.TTF") format("truetype");
} */
* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

html,
body,
#root {
  height: 100%;
}
input {
  outline: none;
}
::placeholder {
  color: red;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-family: arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.03125em;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  word-spacing: -0.028125em;
}

html.fys-app--supported.wf-active,
html:not(.fys-app--supported) {
  font-family: "Open Sans", arial, sans-serif;
  letter-spacing: 0;
  word-spacing: 0;
}

@media screen and (min-width: 1280px) {
  html {
    font-size: 18px;
  }
}

@media screen and (min-width: 1440px) {
  html {
    font-size: 20px;
  }
}

@media screen and (min-width: 1600px) {
  html {
    font-size: 22px;
  }
}

body,
html {
  margin: 0;
  min-height: 100vh;
  padding: 0;
}

body {
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #fff;
  color: #4a4a4a;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.fys-footer,
.fys-header {
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

[role="main"] {
  display: block;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  overflow: hidden;
  padding-top: 7.5rem;
}

@media all and (min-width: 64rem) {
  [role="main"] {
    padding-top: 6.125rem;
  }
}

*,
::after,
::before {
  box-sizing: inherit;
}

.fys-text-primary {
  color: #105ba1;
}

.fys-text-secondary {
  color: #ea2530;
}

img,
svg {
  max-width: 100%;
}

a,
a:active,
a:focus,
a:hover,
a:visited {
  color: #105ba1;
  text-decoration: none;
}

.fys-a11y-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.fys-a11y-visually-hidden.fys-a11y-focusable:active,
.fys-a11y-visually-hidden.fys-a11y-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
}

.fys-content-wrapper {
  margin: 0 auto;
  padding: 1.875rem 1.875rem;
}

@media all and (min-width: 37.5rem) {
  .fys-content-wrapper {
    padding: 2.5rem 2.5rem;
  }
}

@media all and (min-width: 64rem) {
  .fys-content-wrapper {
    padding: 3rem 4rem;
  }
}

.fys-content-wrapper--no-padding-v {
  padding-bottom: 0;
  padding-top: 0;
}

.fys-content-wrapper--max-width {
  max-width: 33.75rem;
}

@media all and (min-width: 37.5rem) {
  .fys-content-wrapper--max-width {
    max-width: 50rem;
  }
}

@media all and (min-width: 64rem) {
  .fys-content-wrapper--max-width {
    max-width: 60.5rem;
  }
}

.fys-drawer,
.fys-header__backdrop {
  height: calc(100vh - 4.375rem);
  position: fixed;
  top: 7.5rem;
  width: 100%;
}

@media all and (min-width: 64rem) {
  .fys-drawer,
  .fys-header__backdrop {
    display: none;
  }
}

.fys-header,
.fys-header::after,
.fys-header__drawer-toggle-label,
.fys-header__home-link {
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease-out;
  will-change: transform;
}

.fys-drawer,
.fys-header::after {
  box-shadow: inset 0 0.5rem 0.75rem -0.625rem rgba(0, 0, 0, 0.4);
}

.fys-drawer__list,
.fys-header__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.fys-drawer__nav-link,
.fys-header__nav-link {
  color: #105ba1;
  display: block;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.5rem 0.75rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}

.fys-drawer__nav-link::after,
.fys-header__nav-link::after {
  background-color: currentColor;
  bottom: 0;
  content: "";
  height: 0.125rem;
  left: 0.75rem;
  position: absolute;
  right: 0.75rem;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  transition: transform 0.1s ease-out;
  will-change: transform;
}

.fys-drawer__nav-link:hover::after,
.fys-header__nav-link:hover::after {
  -ms-transform: none;
  transform: none;
}

@media all and (min-width: 64rem) {
  .fys-drawer__nav-link,
  .fys-header__nav-link {
    font-size: 0.75rem;
  }
}

.fys-drawer__nav-link,
.fys-header__home-link,
.fys-header__nav-link {
  position: relative;
}

.fys-drawer__nav-link::before,
.fys-header__home-link::before,
.fys-header__nav-link::before {
  background-color: currentColor;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.1s ease-out;
  width: 100%;
  will-change: opacity;
}

.fys-drawer__nav-link:focus,
.fys-header__home-link:focus,
.fys-header__nav-link:focus {
  outline: 0;
}

.fys-drawer__nav-link:focus::before,
.fys-header__home-link:focus::before,
.fys-header__nav-link:focus::before {
  opacity: 0.1;
}

.fys-header {
  background-color: #fff;
  position: fixed;
  width: 100%;
  will-change: transform;
  z-index: 2;
}

.fys-header::after {
  background: 0 0;
  content: "";
  height: 0.5rem;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 100%;
  transition-property: opacity;
  will-change: opacity;
}

.fys-header--shadow::after {
  opacity: 1;
}

@media all and (max-width: 63.9375rem) {
  .fys-header--collapsed {
    -ms-transform: translateY(-3.125rem);
    transform: translateY(-3.125rem);
  }
}

.fys-header__wrapper {
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
  height: 7.5rem;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 82rem;
}

@media all and (min-width: 64rem) {
  .fys-header__wrapper {
    -ms-flex-align: center;
    align-items: center;
    height: 6.125rem;
  }
}

.fys-header__skip-to-main.fys-a11y-focusable {
  font-size: 1rem;
  position: absolute;
  z-index: 1;
}

.fys-header__skip-to-main.fys-a11y-focusable:active,
.fys-header__skip-to-main.fys-a11y-focusable:focus {
  left: 0;
  position: absolute;
  top: 0;
  width: auto;
}

@media all and (max-width: 63.9375rem) {
  .fys-header--collapsed .fys-header__skip-to-main.fys-a11y-focusable {
    -ms-transform: translateY(3.125rem);
    transform: translateY(3.125rem);
  }
}

.fys-header__home-link {
  display: block;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 0;
  margin-top: 1.875rem;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
}

@media all and (min-width: 64rem) {
  .fys-header__home-link {
    -ms-flex-item-align: start;
    align-self: flex-start;
    margin-top: 0.5rem;
  }
}

.fys-header__logo {
  display: block;
  height: 4.38499rem;
  width: 8rem;
}

@media all and (min-width: 64rem) {
  .fys-header__logo {
    height: 3.9739rem;
    width: 7.25rem;
  }
}

@media all and (max-width: 63.9375rem) {
  .fys-header--collapsed .fys-header__home-link {
    -ms-transform: translateY(0.5rem) scale(0.7);
    transform: translateY(0.5rem) scale(0.7);
  }
}

.fys-header__nav {
  display: none;
}

@media all and (min-width: 64rem) {
  .fys-header__nav {
    display: block;
    margin-right: -0.75rem;
  }
}

.fys-header__list {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.fys-header__list-item + .fys-header__list-item {
  margin-left: 0.625rem;
}

.fys-header__drawer-toggle-input {
  left: -6.1875rem;
  opacity: 0;
  position: absolute;
  top: -6.1875rem;
  transform: translateZ(0);
}

.fys-header__drawer-toggle-input:focus {
  outline: 0;
}

@media all and (min-width: 64rem) {
  .fys-header__drawer-toggle-input {
    display: none;
  }
}

.fys-header__drawer-toggle-label {
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 3rem;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: -0.75rem;
  margin-top: 1.25rem;
  position: relative;
  width: 3rem;
}

.fys-header__drawer-toggle-label:focus {
  outline: 0;
}

@media all and (min-width: 64rem) {
  .fys-header__drawer-toggle-label {
    display: none;
  }
}

.fys-header__drawer-toggle-label::before {
  background-color: currentColor;
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: opacity 0.1s ease-out;
  width: 100%;
  will-change: opacity;
  z-index: 0;
}

@media all and (max-width: 63.9375rem) {
  .fys-header--collapsed .fys-header__drawer-toggle-label {
    -ms-transform: translateY(2.5625rem);
    transform: translateY(2.5625rem);
  }
}

.fys-header__drawer-toggle-input:focus
  + .fys-header__drawer-toggle-label::before,
.fys-header__drawer-toggle-input:hover
  + .fys-header__drawer-toggle-label::before {
  opacity: 0.05;
}

.fys-header__drawer-toggle-bar {
  background-color: currentColor;
  display: block;
  height: 0.125rem;
  position: relative;
  transition-duration: 0.2s;
  transition-property: transform, opacity;
  transition-timing-function: ease-out;
  width: 1.5rem;
  will-change: transform, opacity;
  z-index: 1;
}

.fys-header__drawer-toggle-bar + .fys-header__drawer-toggle-bar {
  margin-top: 0.25rem;
}

.fys-header__drawer-toggle-input:checked
  + .fys-header__drawer-toggle-label
  .fys-header__drawer-toggle-bar:nth-child(1) {
  -ms-transform: translateY(0.375rem) rotate(45deg) scaleX(0.75);
  transform: translateY(0.375rem) rotateZ(45deg) scaleX(0.75);
}

.fys-header__drawer-toggle-input:checked
  + .fys-header__drawer-toggle-label
  .fys-header__drawer-toggle-bar:nth-child(2) {
  opacity: 0;
}

.fys-header__drawer-toggle-input:checked
  + .fys-header__drawer-toggle-label
  .fys-header__drawer-toggle-bar:nth-child(3) {
  -ms-transform: translateY(-0.375rem) rotate(-45deg) scaleX(0.75);
  transform: translateY(-0.375rem) rotateZ(-45deg) scaleX(0.75);
}

.fys-header__backdrop {
  background-color: #000;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
  will-change: opacity;
  z-index: 1;
}

.fys-header__drawer-toggle-input:checked ~ .fys-header__backdrop {
  opacity: 0.6;
  pointer-events: all;
}

.fys-drawer {
  background-color: #fff;
  left: 100%;
  max-width: 20rem;
  overflow: auto;
  padding-bottom: 3.125rem;
  -ms-transform: none;
  transform: none;
  transition: transform 0.2s ease;
  will-change: transform;
  z-index: 2;
}

.fys-drawer:focus {
  outline: 0;
}

.fys-header__drawer-toggle-input:checked ~ .fys-drawer {
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.fys-drawer__list {
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-left: -0.75rem;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
}

.fys-drawer__list-item + .fys-drawer__list-item {
  margin-top: 0.625rem;
}

@media all and (max-height: 25rem) {
  .fys-drawer__list-item + .fys-drawer__list-item {
    margin-top: 0.25rem;
  }
}

@media all and (max-height: 25rem) {
  .fys-drawer__nav-link {
    padding: 0.25rem 0.375rem;
  }
}

.fys-footer {
  background-color: #404040;
}

.fys-footer,
.fys-footer a {
  color: #fff;
}

.fys-footer a:focus,
.fys-footer a:hover {
  text-decoration: underline;
}

.fys-footer__wrapper {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  max-width: 60.5rem;
  padding-bottom: 2.25rem;
  padding-top: 2.25rem;
}

@media all and (min-width: 37.5rem) {
  .fys-footer__wrapper {
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-bottom: 2.75rem;
    padding-top: 2.75rem;
  }
}

.fys-footer__logo-link {
  color: inherit;
  display: block;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 0;
  margin-bottom: 1.5rem;
  text-decoration: none;
}

@media all and (min-width: 37.5rem) {
  .fys-footer__logo-link {
    margin-bottom: 0;
    margin-top: 0.125rem;
  }
}

.fys-footer__logo {
  height: 2.8125rem;
  width: 9.375rem;
}

@media all and (min-width: 37.5rem) {
  .fys-footer__logo {
    height: 2.25rem;
    width: 7.5rem;
  }
}

@media all and (min-width: 64rem) {
  .fys-footer__logo {
    height: 2.475rem;
    width: 8.25rem;
  }
}

.fys-footer__content-col {
  margin: 0 auto;
  max-width: 33.75rem;
}

@media all and (min-width: 37.5rem) {
  .fys-footer__content-col {
    border-left: 1px solid currentColor;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-left: 1.875rem;
    max-width: none;
    padding-bottom: 0.25rem;
    padding-left: 1.875rem;
  }
}

.fys-footer__list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  list-style: none;
  margin: 0 auto 1.25rem;
  max-width: 25rem;
  padding: 0;
}

@media all and (min-width: 37.5rem) {
  .fys-footer__list {
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-left: -0.5rem;
    margin-top: -0.3125rem;
    max-width: 21.875rem;
  }
}

@media all and (min-width: 48rem) {
  .fys-footer__list {
    max-width: none;
  }
}

@media all and (min-width: 64rem) {
  .fys-footer__list {
    margin-top: -0.3125rem;
  }
}

.fys-footer__list-item {
  padding: 0.25rem;
}

.fys-footer__nav-link {
  color: inherit;
  display: block;
  font-size: 0.75rem;
  padding: 0.25rem 0.375rem;
  text-decoration: none;
  text-transform: uppercase;
}

@media all and (min-width: 37.5rem) {
  .fys-footer__nav-link {
    font-size: 0.625rem;
  }
}

@media all and (min-width: 64rem) {
  .fys-footer__nav-link {
    font-size: 0.75rem;
  }
}

.fys-footer__content {
  color: inherit;
  font-size: 0.875rem;
  text-align: center;
}

.fys-footer__content a {
  text-decoration: underline;
}

@media all and (min-width: 37.5rem) {
  .fys-footer__content {
    font-size: 0.6875rem;
    text-align: left;
  }
}

@media all and (min-width: 64rem) {
  .fys-footer__content {
    font-size: 0.75rem;
  }
}

.fys-picture {
  display: block;
  font-size: 0;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
}

.fys-picture img {
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.fys-picture--portrait img {
  height: auto;
  width: 100%;
}

.fys-picture--landscape img {
  height: 100%;
  width: auto;
}

.fys-picture noscript ~ * {
  display: none;
}

.fys-app--supported .fys-picture noscript ~ * {
  display: block;
}

.fys-app--supported .fys-picture--lazy img {
  filter: blur(10px);
}

.fys-app--supported .fys-picture--lazy.fys-picture--loaded img {
  filter: none;
  transition: 0.3s filter ease-out;
}

.fys-section__diagonal,
.fys-section__spacer {
  height: 10vw;
}

.fys-section {
  background-color: #fff;
  position: relative;
}

.fys-section--bg-primary {
  background-color: #124e87;
  color: #fff;
}

.fys-section--bg-secondary {
  background-color: #ea2530;
  color: #fff;
}

.fys-section--bg-alt {
  background-color: #f7f7f7;
  color: #4a4a4a;
}

.fys-section--diagonal .fys-section__wrapper {
  padding-top: 30px;
}

@media all and (min-width: 37.5rem) {
  .fys-section--diagonal .fys-section__wrapper {
    padding-top: 40px;
  }
}

@media all and (min-width: 64rem) {
  .fys-section--diagonal .fys-section__wrapper {
    padding-top: 64px;
  }
}

.fys-section--spacer .fys-section__wrapper {
  padding-bottom: 30px;
}

.fys-section__spacer {
  background-color: inherit;
  width: 100%;
}

.fys-section__diagonal {
  bottom: 100%;
  fill: #fff;
  left: 0;
  pointer-events: none;
  position: absolute;
  -ms-transform: translateY(1px);
  transform: translateY(1px);
  width: 100%;
}

.fys-section--bg-primary .fys-section__diagonal {
  fill: #124e87;
}

.fys-section--bg-secondary .fys-section__diagonal {
  fill: #ea2530;
}

.fys-section--bg-alt .fys-section__diagonal {
  fill: #f7f7f7;
}

.fys-section__diagonal-path-rtl {
  display: none;
}

[dir="rtl"] .fys-section__diagonal-path-rtl {
  display: block;
}

.fys-section__diagonal--reverse .fys-section__diagonal-path-rtl {
  display: block;
}

[dir="rtl"] .fys-section__diagonal--reverse .fys-section__diagonal-path-rtl {
  display: none;
}

.fys-section__diagonal-path-ltr {
  display: block;
}

[dir="rtl"] .fys-section__diagonal-path-ltr {
  display: none;
}

.fys-section__diagonal--reverse .fys-section__diagonal-path-ltr {
  display: none;
}

[dir="rtl"] .fys-section__diagonal--reverse .fys-section__diagonal-path-ltr {
  display: block;
}

.fys-button,
a.fys-button {
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
  border: 0;
  border-radius: 0.3125rem;
  color: inherit;
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  overflow: visible;
  padding: 0.84em 1.4em 0.86em;
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
  width: auto;
}

@media all and (min-width: 52.5rem) {
  .fys-button,
  a.fys-button {
    font-size: 0.875rem;
  }
}

@media all and (min-width: 64rem) {
  .fys-button,
  a.fys-button {
    font-size: 1.0625rem;
  }
}

.fys-button::-moz-focus-inner,
a.fys-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.fys-button::after,
a.fys-button::after {
  border-radius: inherit;
  border-style: solid;
  border-width: 0.125rem;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.fys-button:focus,
.fys-button:hover,
a.fys-button:focus,
a.fys-button:hover {
  outline: 0;
}

.fys-button:focus::after,
.fys-button:hover::after,
a.fys-button:focus::after,
a.fys-button:hover::after {
  opacity: 1;
}

.fys-button--primary,
a.fys-button--primary {
  background-color: #105ba1;
  color: #fff;
}

.fys-button--primary::after,
a.fys-button--primary::after {
  background: rgba(16, 91, 161, 0.1);
  border-color: #105ba1;
}

.fys-button--primary:focus,
.fys-button--primary:hover,
a.fys-button--primary:focus,
a.fys-button--primary:hover {
  background-color: #fff;
  color: #105ba1;
}

.fys-button--secondary,
a.fys-button--secondary {
  background-color: #fff;
  color: #105ba1;
  position: relative;
}

.fys-button--secondary::after,
a.fys-button--secondary::after {
  background: rgba(255, 255, 255, 0.1);
  border-color: #fff;
}

.fys-button--secondary:focus,
.fys-button--secondary:hover,
a.fys-button--secondary:focus,
a.fys-button--secondary:hover {
  background-color: #105ba1;
  color: #fff;
}

.fys-markdown-text * {
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  text-align: inherit;
}

.fys-markdown-text :not(.fys-text-primary):not(.fys-text-secondary):not(a) {
  color: inherit;
}

.fys-markdown-text p + p,
.fys-markdown-text p + ul,
.fys-markdown-text ul + p,
.fys-markdown-text ul + ul {
  margin-top: 1em;
}

.fys-home-hero {
  background: linear-gradient(
    to right,
    #124e87,
    #124e87 50%,
    #9f0100 50%,
    #9f0100
  );
  overflow: hidden;
}

.fys-home-hero .fys-section__wrapper {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-bottom: 3.125rem;
  padding-top: 3.125rem;
}

@media all and (min-width: 52.5rem) {
  .fys-home-hero .fys-section__wrapper {
    padding-bottom: 3.75rem;
    padding-top: 3.75rem;
  }
}

.fys-home-hero__pattern--blue,
.fys-home-hero__pattern--red {
  background-repeat: repeat;
  position: absolute;
  width: 50%;
  z-index: 0;
}

.fys-home-hero__pattern--red {
  background-size: contain;
  height: 24vw;
  left: 65%;
  top: 63vw;
}

@media all and (min-width: 23.4375rem) {
  .fys-home-hero__pattern--red {
    height: 8.75rem;
    top: 11.625rem;
  }
}

@media all and (min-width: 52.5rem) {
  .fys-home-hero__pattern--red {
    height: 11.25rem;
    left: 60%;
    top: 11.25rem;
  }
}

@media all and (min-width: 64rem) {
  .fys-home-hero__pattern--red {
    height: 14rem;
    left: 55%;
    top: 10.25rem;
  }
}

.fys-home-hero__pattern--blue {
  background-position: top right;
  background-size: 145px 240px;
  filter: blur(1.5px);
  height: 100%;
  left: 0;
  top: 0;
}

.fys-home-hero__heart-wrapper {
  margin: 0 auto 0.625rem;
  max-width: 19.6875rem;
  position: relative;
  width: 100%;
  z-index: 1;
}

@media all and (min-width: 23.4375rem) {
  .fys-home-hero__heart-wrapper {
    max-width: none;
    width: 19.6875rem;
  }
}

@media all and (min-width: 52.5rem) {
  .fys-home-hero__heart-wrapper {
    width: 21.25rem;
  }
}

@media all and (min-width: 64rem) {
  .fys-home-hero__heart-wrapper {
    width: 22.5rem;
  }
}

.fys-home-hero__text-wrapper {
  color: #fff;
  margin: 0 auto;
  max-width: 25rem;
  position: relative;
  text-align: center;
  z-index: 1;
}

@media all and (min-width: 37.5rem) {
  .fys-home-hero__text-wrapper {
    max-width: 31.25rem;
  }
}

.fys-home-hero__title {
  font-size: 1.125rem;
  margin: 0 auto 0.5rem;
}

@media all and (min-width: 64rem) {
  .fys-home-hero__title {
    font-size: 1.25rem;
  }
}

.fys-home-hero__description {
  font-size: 0.875rem;
  margin: 0 auto 2rem;
}

@media all and (min-width: 64rem) {
  .fys-home-hero__description {
    font-size: 0.9375rem;
    margin-bottom: 2.25rem;
  }
}

.fys-home-collab .fys-content-wrapper {
  padding-bottom: 1.17188rem;
  padding-left: 0;
  padding-right: 0;
}

@media all and (min-width: 37.5rem) {
  .fys-home-collab .fys-content-wrapper {
    padding-bottom: 1.5625rem;
  }
}

@media all and (min-width: 64rem) {
  .fys-home-collab .fys-content-wrapper {
    padding-bottom: 1.875rem;
  }
}

.fys-home-collab__hands-wrapper {
  left: 50%;
  position: absolute;
  top: 0;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 128rem;
}

.fys-home-collab__hands-wrapper::after,
.fys-home-collab__hands-wrapper::before {
  content: "";
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50vw;
}

.fys-home-collab__hands-wrapper::before {
  background: #028ad0;
  height: 1.85rem;
  right: calc(100% - 0.125rem);
  top: calc(50% + 0.09375rem);
}

.fys-home-collab__hands-wrapper::after {
  background: #ec2330;
  height: 1.53125rem;
  left: calc(100% - 0.125rem);
  top: calc(50% + 0.25rem);
}

.fys-home-collab__hands-wrapper .fys-picture {
  left: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.fys-home-collab__list {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.fys-home-collab__list-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  position: relative;
}

.fys-home-collab__list-item:nth-child(2n + 1)::after {
  background: #ccc;
  bottom: -0.75rem;
  content: "";
  position: absolute;
  right: 0;
  top: 0.125rem;
  width: 0.0625rem;
}

.fys-home-collab__list-item:not(:nth-child(-n + 2)) {
  margin-top: 1.25rem;
}

@media all and (min-width: 52.5rem) {
  .fys-home-collab__list-item:not(:nth-child(-n + 2)) {
    margin-top: 2.5rem;
  }
}

.fys-home-collab__logo-wrapper {
  display: block;
}

.fys-home-collab__list-item:nth-child(1) .fys-home-collab__logo-wrapper {
  width: 7.625rem;
}

.fys-home-collab__list-item:nth-child(2) .fys-home-collab__logo-wrapper {
  margin-top: 1rem;
  width: 8.625rem;
}

.fys-home-facts a:focus,
.fys-home-facts a:hover {
  text-decoration: underline;
}

.fys-home-facts__text {
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0 auto 2.5rem;
  max-width: 14em;
  text-align: center;
}

@media all and (min-width: 30rem) {
  .fys-home-facts__text {
    max-width: 18em;
  }
}

@media all and (min-width: 37.5rem) {
  .fys-home-facts__text {
    margin-bottom: 3.75rem;
  }
}

@media all and (min-width: 52.5rem) {
  .fys-home-facts__text {
    -ms-flex: 1 0 50%;
    flex: 1 0 50%;
    max-width: 32em;
  }
  .fys-home-facts__text p + p {
    margin-top: 0;
  }
}

@media all and (min-width: 64rem) {
  .fys-home-facts__text {
    font-size: 1.375rem;
  }
}

.fys-home-facts__fact-list {
  list-style: none;
  margin: 0 auto;
  max-width: 26.25rem;
  padding: 0;
}

@media all and (min-width: 37.5rem) {
  .fys-home-facts__fact-list {
    max-width: 31.25rem;
  }
}

@media all and (min-width: 52.5rem) {
  .fys-home-facts__fact-list {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: none;
  }
}

.fys-home-facts__fact-list-item {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.fys-home-facts__fact-list-item + .fys-home-facts__fact-list-item {
  margin-top: 1.75rem;
}

@media all and (min-width: 37.5rem) {
  .fys-home-facts__fact-list-item + .fys-home-facts__fact-list-item {
    margin-top: 3rem;
  }
}

@media all and (min-width: 52.5rem) {
  .fys-home-facts__fact-list-item {
    -ms-flex: 1 0 50%;
    flex: 1 0 50%;
  }
  .fys-home-facts__fact-list-item:not(:nth-child(n + 3)) {
    margin-top: 0;
  }
}

.fys-home-facts__fact-icon {
  -ms-flex: 0 0 6.125rem;
  flex: 0 0 6.125rem;
  margin-right: 1.5rem;
  max-height: 4.75rem;
  width: 6.125rem;
}

@media all and (min-width: 37.5rem) {
  .fys-home-facts__fact-icon {
    -ms-flex-preferred-size: 7.5rem;
    flex-basis: 7.5rem;
    margin-right: 1.875rem;
    max-height: 5.125rem;
    width: 7.5rem;
  }
}

@media all and (min-width: 52.5rem) {
  .fys-home-facts__fact-icon {
    -ms-flex-preferred-size: 6.875rem;
    flex-basis: 6.875rem;
    margin-left: 1.875rem;
    margin-right: 1.875rem;
    max-height: 4.75rem;
    width: 6.875rem;
  }
}

@media all and (min-width: 64rem) {
  .fys-home-facts__fact-icon {
    -ms-flex-preferred-size: 7.5rem;
    flex-basis: 7.5rem;
    margin-left: 2.25rem;
    margin-right: 2.25rem;
    max-height: 5.375rem;
    width: 7.5rem;
  }
}

@media all and (min-width: 52.5rem) {
  .fys-home-facts__fact-list-item:nth-child(2n + 1) .fys-home-facts__fact-icon {
    margin-left: 0;
  }
}

@media all and (min-width: 64rem) {
  .fys-home-facts__fact-list-item:nth-child(2n + 1)
    .fys-home-facts__fact-description {
    padding-right: 3em;
  }
}

.fys-home-facts__fact-description {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 0.875rem;
  margin: 0;
}

@media all and (min-width: 52.5rem) {
  .fys-home-facts__fact-description {
    font-size: 0.75rem;
  }
}

@media all and (min-width: 64rem) {
  .fys-home-facts__fact-description {
    font-size: 0.875rem;
  }
}

.fys-home-footnotes__list-item,
.fys-home-footnotes__list::before {
  display: inline;
  font-size: 0.75rem;
  line-height: 1;
}

@media all and (min-width: 52.5rem) {
  .fys-home-footnotes__list-item,
  .fys-home-footnotes__list::before {
    font-size: 0.6875rem;
  }
}

.fys-home-footnotes a:focus,
.fys-home-footnotes a:hover {
  text-decoration: underline;
}

.fys-home-footnotes .fys-content-wrapper {
  padding-top: 1rem;
}

@media all and (max-width: 52.4375rem) {
  .fys-home-footnotes .fys-content-wrapper {
    max-width: none;
  }
}

.fys-home-footnotes__list {
  line-height: 1.2;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.fys-home-footnotes__list::before {
  content: attr(data-list-title);
}

@media all and (min-width: 52.5rem) {
  .fys-home-footnotes__list {
    line-height: 1;
  }
}

.fys-home-footnotes__list-item {
  position: relative;
}

.fys-home-footnotes__list-item + .fys-home-footnotes__list-item::before {
  content: "|";
  padding: 0 0.4em;
}

.fys-home-footnotes__list-item * {
  font: inherit;
  line-height: inherit;
}

.fys-home-footnotes__spy {
  display: block;
  font-size: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: -9.375rem;
  visibility: hidden;
}

.fys-home-footnotes__spy:target ~ * {
  text-decoration: underline;
}

@media all and (min-width: 64rem) {
  .fys-home-footnotes__spy {
    top: -8rem;
  }
}

[data-footnote-index-after]::after {
  content: "[" attr(data-footnote-index-after) "]";
  cursor: pointer;
  font-size: 0.7em;
  padding-left: 0.2em;
  vertical-align: super;
}

.fys-page--404 [role="main"] {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}

.fys-page--404 .fys-section {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  max-width: 26.25rem;
  padding-bottom: 4rem;
  padding-top: 2rem;
  text-align: center;
}

.fys-page--404 .fys-section :first-child {
  margin-top: auto;
}

.fys-page--404 .fys-section :last-child {
  margin-bottom: auto;
}

@media all and (min-width: 37.5rem) {
  .fys-page--404 .fys-section {
    max-width: 31.25rem;
  }
}

@media all and (min-width: 64rem) {
  .fys-page--404 .fys-section {
    max-width: 37.5rem;
  }
}

.fys-page--404 h1 {
  color: #ea2530;
  font-size: 1.625rem;
  margin-bottom: 1em;
}

@media all and (min-width: 52.5rem) {
  .fys-page--404 h1 {
    font-size: 1.375rem;
  }
}

@media all and (min-width: 64rem) {
  .fys-page--404 h1 {
    font-size: 1.625rem;
  }
}

.fys-page--404 p {
  font-size: 1rem;
  margin-bottom: 2em;
}

@media all and (min-width: 52.5rem) {
  .fys-page--404 p {
    font-size: 0.875rem;
  }
}

@media all and (min-width: 64rem) {
  .fys-page--404 p {
    font-size: 1rem;
  }
}
